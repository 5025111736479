// @flow
import {useEffect, useRef, useState, useMemo, useCallback} from 'react';
import {
    ComponentPainter,
    COMPONENT_TYPES,
    FIELD_TYPES,
    useConfirmationDialog,
} from 'shared';
import {useFormikContext} from 'formik';
import {useProductContext} from 'contexts';
import excel from 'shared/Excel';
import {toFixed, useQueryParams} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {FIELDSETS} from 'components/customer/Product';
import {useStickyCanvas} from 'hooks';
import {throttle, cloneDeep} from 'lodash';
import type {MaterialOptions, ShelvesPreviewOption} from 'shared/types';
import {Field} from 'components/customer/Product/entity/Field';
import {Fieldset} from 'components/customer/Product/entity/Fieldset';
import {usePreviewContext} from 'components/customer/Product/Preview/PreviewContext';
import {CSSProperties} from 'styled-components';

export const POSITION_TYPES = {
    PERCENTAGE: 'percentage',
    DECIMAL: 'decimal',
};

export const useShelves = (fields: Field, fieldset: Fieldset) => {
    const {values, setFieldValue} = useFormikContext();
    const {dialog, showDialog, hideDialog} = useConfirmationDialog();
    const {
        productDataStore,
        resetNewShelf,
        newShelfStore,
        setHeightReference,
        shelvesHeightUpdated,
        shelvesHeightReference,
        simpleShelvesReference,
        shelvesUpdatedReference,
    } = useProductContext();
    const fieldsetName = useRef(fieldset.name).current;

    const [shelfFieldsets, setShelfFieldsets] = useState([]);
    const isLimitReached = useMemo<boolean>(() => {
        if (
            fieldset.hasOwnProperty('options') &&
            fieldset.options.hasOwnProperty('maximum')
        ) {
            const shelves = values.hasOwnProperty(fieldsetName)
                ? values[fieldsetName]
                : [];
            return shelves.length >= fieldset.options.maximum;
        }

        return false;
    }, [values[fieldsetName]]);

    const {cabinetId, product: productId, importid} = useQueryParams();
    const autoShelvesQuantity = fieldset.options.hasOwnProperty(
        'autoShelfQuantity'
    )
        ? fieldset.options.autoShelfQuantity
        : false;

    const getSimpleShelfStyle = () => {
        const shelfStyleFieldName =
            fieldset.hasOwnProperty('options') &&
            fieldset.options.hasOwnProperty('autoShelfStyle')
                ? fieldset.options.autoShelfStyle
                : false;
        return shelfStyleFieldName ? values[shelfStyleFieldName] : null;
    };

    const getFields = (): Object => {
        const fields = {};
        fieldset.fields.forEach((field) => {
            fields[field.name] =
                field.type !== FIELD_TYPES.SELECT && field.value === null
                    ? ''
                    : field.value;
        });

        return fields;
    };

    const getFieldName = (object: Object, keyHint: string): string => {
        const keys = Object.keys(object);
        const relatedKeys = keys.find((key) => key.indexOf(keyHint) > -1);

        if (relatedKeys) {
            return relatedKeys;
        }

        return '';
    };

    const getDefaultShelvesAmount = (key) => {
        return productDataStore.current.hasOwnProperty('cabinet') &&
            productDataStore.current.cabinet.hasOwnProperty('attributes') &&
            productDataStore.current.cabinet.attributes &&
            productDataStore.current.cabinet.attributes.hasOwnProperty(key)
            ? productDataStore.current.cabinet.attributes[key]
            : 0;
    };

    const effectModifiers = [productId, cabinetId ? cabinetId : 0];
    if (
        fieldset.hasOwnProperty('options') &&
        fieldset.options.hasOwnProperty('autoShelfQuantity')
    ) {
        effectModifiers.push(values[fieldset.options.autoShelfQuantity]);
    } else effectModifiers.push(0);

    useEffect(() => {
        const shelfFieldset = [...shelfFieldsets];
        const shelvesQuantity =
            autoShelvesQuantity && values.hasOwnProperty(autoShelvesQuantity)
                ? values[autoShelvesQuantity]
                : false;

        let positions = values.hasOwnProperty(fieldsetName)
            ? [...values[fieldsetName]]
            : [];
        let count = !shelvesQuantity ? positions.length : shelvesQuantity;

        const isShelvesTouched =
            shelvesUpdatedReference.current.hasOwnProperty(fieldsetName);

        if (
            typeof cabinetId === 'undefined' &&
            count === 0 &&
            !isShelvesTouched &&
            typeof importid === 'undefined'
        ) {
            switch (fieldsetName) {
                case FIELDSETS.UPPER_SHEPVES:
                    count = getDefaultShelvesAmount('default_upper_shelves');

                    if (count > 99) {
                        if (
                            fieldset.options.hasOwnProperty(
                                'shelfSectionHeight'
                            )
                        ) {
                            try {
                                count = Math.round(
                                    excel.calculate(
                                        fieldset.options.shelfSectionHeight,
                                        values
                                    ) /
                                        getDefaultShelvesAmount(
                                            'default_upper_shelves'
                                        )
                                );
                            } catch (e) {
                                count = 1;
                            }
                        } else count = 1;
                    }

                    break;
                case FIELDSETS.MIDDLE_SHELVES:
                    count = getDefaultShelvesAmount('default_middle_shelves');

                    break;
                case FIELDSETS.LOWER_SHELVES:
                    count = getDefaultShelvesAmount('default_lower_shelves');

                    if (count > 99) {
                        if (
                            fieldset.options.hasOwnProperty(
                                'shelfSectionHeight'
                            )
                        ) {
                            try {
                                count = Math.round(
                                    excel.calculate(
                                        fieldset.options.shel3fSectionHeight,
                                        values
                                    ) /
                                        getDefaultShelvesAmount(
                                            'default_lower_shelves'
                                        )
                                );
                            } catch (e) {
                                count = 1;
                            }
                        } else count = 1;
                    }
                    break;

                case FIELDSETS.SHELVES:
                    if (!values.hasOwnProperty('simple_shelves_amount')) {
                        count = getDefaultShelvesAmount('default_shelves');

                        if (count > 99) {
                            if (
                                fieldset.options.hasOwnProperty(
                                    'shelfSectionHeight'
                                )
                            ) {
                                try {
                                    count = Math.round(
                                        excel.calculate(
                                            fieldset.options.shelfSectionHeight,
                                            values
                                        ) /
                                            getDefaultShelvesAmount(
                                                'default_lower_shelves'
                                            )
                                    );
                                } catch (e) {
                                    count = 1;
                                }
                            } else count = 1;
                        }
                    }

                    break;
            }
        }

        const reCalculate =
            simpleShelvesReference.current != values.simple_shelves_amount;

        if (shelfFieldset.length < positions.length || positions.length == 0) {
            for (let i = 0; i < count; i++) {
                shelfFieldset.push({...fieldset});

                let position = getFields();
                if (positions[i]) {
                    position = {...position, ...positions[i]};
                }

                const positionField: string = getFieldName(
                    position,
                    'position'
                );
                const offsetField: string = getFieldName(position, 'offset');
                const styleField: string = getFieldName(position, 'style');
                const typeField: string = getFieldName(position, 'shelf_type');

                if (typeof positions[i] === 'undefined' || reCalculate) {
                    simpleShelvesReference.current =
                        values.simple_shelves_amount;
                    position[styleField] = getSimpleShelfStyle();
                    position[offsetField] = values.shelf_set_back
                        ? parseFloat(values.shelf_set_back)
                        : 5.0;
                    position[positionField] = toFixed((i + 1) / (count + 1), 4);
                } else {
                    if (position.hasOwnProperty('insert_id')) {
                        position[typeField] = position.insert_id;
                    }

                    if (position.hasOwnProperty('shelf_style')) {
                        position[styleField] = position.shelf_style;
                    }
                }

                positions[i] = position;
            }
        }

        if (count < positions.length) {
            positions = positions.slice(0, parseInt(shelvesQuantity));
        }

        setFieldValue(fieldsetName, positions);
        setShelfFieldsets(shelfFieldset);
    }, effectModifiers);

    const getHeightValue = (shelfHeightRefFieldName, scope) => {
        try {
            return excel.calculate(shelfHeightRefFieldName, scope);
        } catch (e) {
            return values.cabinet_height;
        }
    };

    const shelfHeightRefFieldName =
        fieldset.hasOwnProperty('options') &&
        fieldset.options.hasOwnProperty('shelfSectionHeight')
            ? fieldset.options.shelfSectionHeight
            : false;
    const relevantHeightValues = useRef({});

    useEffect(() => {
        const heightRefEffectModifiers = shelfHeightRefFieldName
            ? shelfHeightRefFieldName
                  .split(' ')
                  .filter(
                      (effectModifier) =>
                          !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(
                              effectModifier
                          )
                  )
            : [];
        const relevantValues = heightRefEffectModifiers
            .map((field) => {
                if (values.hasOwnProperty(field)) {
                    return {[field]: values[field]};
                } else {
                    if (typeof field === 'string' && field !== '') {
                        try {
                            return {[field]: excel.calculate(field, values)};
                        } catch (e) {
                            return {[field]: 0};
                        }
                    }
                }
            })
            .reduce((accumulator, current) => {
                const key = Object.keys(current)[0];
                accumulator[key] = current[key];

                return accumulator;
            });

        Object.keys(relevantValues).forEach((key) => {
            // This ended up becoming more static field. These values are stored in object store as percent values and also needs reference to total height to
            // convert those percentage to mm
            if (
                key === 'cabinet_lower_partition_height' ||
                key === 'cabinet_upper_partition_height'
            ) {
                relevantValues[key] =
                    relevantValues[key] *
                    shelvesHeightReference.current.partition_heights;
            }
        });

        if (heightRefEffectModifiers.length) {
            let shelfHeightRefValue;

            if (!shelfHeightRefFieldName) {
                // if shelfSectionHeight field is not set or cannot somehow calculate the height use cabinet height
                shelfHeightRefValue = values.cabinet_height;
            } else {
                if (isNaN(shelfHeightRefValue) && shelfHeightRefValue !== '') {
                    shelfHeightRefValue = getHeightValue(
                        shelfHeightRefFieldName,
                        relevantValues
                    );

                    if (typeof shelfHeightRefValue === 'string') {
                        shelfHeightRefValue = getHeightValue(
                            shelfHeightRefValue,
                            values
                        );
                    }
                }
            }

            setHeightReference(fieldsetName, shelfHeightRefValue);
        }
    }, [
        values.cabinet_height,
        values.cabinet_total_drawer_height,
        values.cabinet_toekick,
        values.cabinet_partition_height,
        shelvesHeightUpdated,
    ]);

    const autoPosition = useCallback(() => {
        let shelves = cloneDeep(values)[fieldsetName];

        shelves = shelves.map((shelf, i) => {
            const positionField = getFieldName(shelf, 'position');

            return {
                ...shelf,
                ...{
                    [positionField]: toFixed((i + 1) / (shelves.length + 1), 4),
                },
            };
        });

        setFieldValue(fieldsetName, shelves);
    }, [values]);

    const setFieldsetAndPositions = (fieldsetName, fieldsets, positions) => {
        setShelfFieldsets(fieldsets);
        setFieldValue(fieldsetName, positions);

        if (values.hasOwnProperty('simple_shelves_amount')) {
            setFieldValue('simple_shelves_amount', fieldsets.length);
            simpleShelvesReference.current = fieldsets.length;
        }

        if (!shelvesUpdatedReference.current.hasOwnProperty(fieldsetName))
            shelvesUpdatedReference.current[fieldsetName] = true;
    };

    const addShelfDialog = useCallback(
        (dialogContent) => {
            const dialogOptions = {
                title: 'Add Shelf',
                message: dialogContent,
                hideYesButton: true,
                hideNoButton: true,
                buttons: [
                    {
                        name: 'Add',
                        controlledHideDialog: true,
                        action: () => {
                            const fieldsets = cloneDeep(shelfFieldsets);
                            let positions = cloneDeep(values)[fieldsetName];

                            const position = getFields();
                            const positionField = getFieldName(
                                position,
                                'position'
                            );
                            const offsetField = getFieldName(
                                position,
                                'offset'
                            );
                            const styleField = getFieldName(position, 'style');

                            position[styleField] = getSimpleShelfStyle();
                            position[offsetField] = values.shelf_set_back
                                ? parseFloat(values.shelf_set_back)
                                : 5.0;

                            if (!newShelfStore.current.autoPosition) {
                                position[positionField] =
                                    newShelfStore.current.position;
                            }

                            positions.push(position);

                            if (newShelfStore.current.autoPosition) {
                                positions = positions.map((position, index) => {
                                    position[positionField] = toFixed(
                                        (1 + index) / (positions.length + 1),
                                        4
                                    );

                                    return position;
                                });
                            }

                            fieldsets.push(fieldset);

                            setFieldsetAndPositions(
                                fieldsetName,
                                fieldsets,
                                positions
                            );

                            resetNewShelf();
                            hideDialog();
                        },
                        show: true,
                    },
                    {
                        name: 'Cancel',
                        variant: 'warning',
                        show: true,
                    },
                ],
            };

            showDialog(dialogOptions);
        },
        [values, shelfFieldsets, fieldset]
    );

    const deleteShelfDialog = useCallback(
        (index) => {
            const fieldsets = cloneDeep(shelfFieldsets);
            let positions = cloneDeep(values)[fieldsetName];

            const triggerDeleteOnly = () => {
                fieldsets.splice(index, 1);
                positions.splice(index, 1);

                setFieldsetAndPositions(fieldsetName, fieldsets, positions);
            };

            if (fieldsets.length <= 1) {
                // Delete the remaining shelf since no need to do auto positions for empty shelf
                triggerDeleteOnly();
                return;
            }

            showDialog({
                title: 'Delete Shelf',
                message:
                    'Would you like to auto-position the remaining shelves?',
                yes: () => {
                    fieldsets.splice(index, 1);
                    positions.splice(index, 1);

                    positions = positions.map((position, i) => {
                        const positionField = getFieldName(
                            position,
                            'position'
                        );

                        position[positionField] = toFixed(
                            (1 + i) / (positions.length + 1),
                            4
                        );

                        return position;
                    });

                    setFieldsetAndPositions(fieldsetName, fieldsets, positions);
                },
                no: triggerDeleteOnly,
                buttons: [
                    {
                        name: 'Cancel',
                        variant: 'warning',
                        show: true,
                    },
                ],
            });
        },
        [values, shelfFieldsets]
    );

    const copyShelfDialog = useCallback(
        (index) => {
            const fieldsets = cloneDeep(shelfFieldsets);
            let positions = cloneDeep(values)[fieldsetName];

            const dialogOptions = {
                title: 'Copy Shelf',
                message: 'Would you like to auto-position the shelves?',
                yes: () => {
                    fieldsets.splice(index + 1, 0, {...fieldsets[index]});
                    positions.splice(index + 1, 0, {...positions[index]});

                    positions = positions.map((position, i) => {
                        const positionField = getFieldName(
                            position,
                            'position'
                        );

                        position[positionField] = toFixed(
                            (1 + i) / (positions.length + 1),
                            4
                        );

                        return position;
                    });

                    setFieldsetAndPositions(fieldsetName, fieldsets, positions);
                },
                no: () => {
                    fieldsets.splice(index + 1, 0, {...fieldsets[index]});
                    positions.splice(index + 1, 0, {...positions[index]});

                    setFieldsetAndPositions(fieldsetName, fieldsets, positions);
                },
                buttons: [
                    {
                        name: 'Cancel',
                        variant: 'warning',
                        show: true,
                    },
                ],
            };

            showDialog(dialogOptions);
        },
        [values, shelfFieldsets]
    );

    return {
        dialog,
        fieldsetName,
        addShelfDialog,
        deleteShelfDialog,
        copyShelfDialog,
        shelfFieldsets,
        autoPosition,
        isLimitReached,
    };
};

export const getShelvesOptions = ({
    values,
    productDataStore,
    materials,
    previewOptions,
    includeVoid = false,
}: ShelvesPreviewOptionType): Object => {
    let width: number = values.cabinet_width;
    let height: number = parseInt(values.cabinet_height);

    if (typeof height === 'undefined') {
        height = parseInt(values.cabinet_panel_length);
    }

    if (typeof width === 'undefined') {
        width = parseInt(values.cabinet_panel_width);
    }

    if (
        (typeof width === 'undefined' || isNaN(width)) &&
        values.hasOwnProperty('cabinet_left_width') &&
        values.hasOwnProperty('cabinet_right_width') &&
        values.hasOwnProperty('cabinet_left_depth') &&
        values.hasOwnProperty('cabinet_right_depth')
    ) {
        width = Math.sqrt(
            Math.pow(
                values.cabinet_left_width - values.cabinet_right_depth,
                2
            ) +
                Math.pow(
                    values.cabinet_right_width - values.cabinet_left_depth,
                    2
                )
        );
    }

    if (typeof width === 'undefined' || isNaN(width)) {
        width = parseInt(values.cabinet_applied_panel_depth);
    }

    const matType = productDataStore.current.cabinet.attributes.mat_type;
    const carcaseColour =
        matType != 1 ? materials.carcase_color : materials.exterior_color;

    const carcaseEdgeColour =
        matType != 1
            ? materials.carcase_edge_color
            : materials.exterior_edge_color;

    const horizontal =
        matType != 1 ? values.hor_grain_carc : values.hor_grain_ext;

    let carcaseImage = carcaseColour
        ? carcaseColour.indexOf('uploads/gocabinet_materials') > -1
            ? `${carcaseColour}`
            : `/uploads/gocabinet_materials/${carcaseColour}`
        : '';
    let carcaseEdgeImage = carcaseEdgeColour
        ? carcaseEdgeColour.indexOf('uploads/gocabinet_materials') > -1
            ? `${carcaseEdgeColour}`
            : `/uploads/gocabinet_materials/${carcaseEdgeColour}`
        : '';

    const extColour = materials.exterior_color;
    const extImage = extColour
        ? extColour.indexOf('uploads/gocabinet_materials') > -1
            ? `${extColour}`
            : `/uploads/gocabinet_materials/${extColour}`
        : '';

    const extEdgeColour = materials.exterior_edge_color;
    const extEdgeImage = extEdgeColour
        ? extEdgeColour.indexOf('uploads/gocabinet_materials') > -1
            ? `${extEdgeColour}`
            : `/uploads/gocabinet_materials/${extEdgeColour}`
        : '';

    if (values.cabinet_hori_shelves && values.cabinet_vert_shelves) {
        carcaseImage = extImage;
        carcaseEdgeImage = extEdgeImage;
    }

    let isInner: boolean = false;
    let innerText: string;
    let partitionHeight: number = values.cabinet_partition_height;
    let upperShelves: Array<any> = values.upper_shelves;
    let shelvesMapper: Object = undefined;
    let faceHeight: number = 0;

    if (
        productDataStore.current.hasOwnProperty('cabinet') &&
        productDataStore.current.cabinet.hasOwnProperty('attributes') &&
        productDataStore.current.cabinet.attributes.hasOwnProperty(
            'inner_drawers'
        )
    ) {
        if (productDataStore.current.cabinet.attributes.inner_drawers > 0) {
            innerText = 'Inner Drawers';
        }
    }

    if (
        productDataStore.current.hasOwnProperty('cabinet') &&
        productDataStore.current.cabinet.hasOwnProperty('attributes') &&
        productDataStore.current.cabinet.attributes.hasOwnProperty(
            'original_name'
        ) &&
        productDataStore.current.cabinet.attributes.original_name
            .toLowerCase()
            .indexOf('inner') > -1
    ) {
        isInner = true;
    }

    if (values.cabinet_total_drawer_height) {
        isInner = true;
        partitionHeight = values.cabinet_total_drawer_height;
        upperShelves = values.shelves;
        innerText = 'Drawers';

        if (values.drawer_face_height_default) {
            faceHeight = excel.calculate(
                values.drawer_face_height_default,
                values
            );
        } else {
            faceHeight = partitionHeight;
        }

        shelvesMapper = {
            label: 'Shelf',
            position: 'shelf_position',
            type: 'shelf_type',
            style: 'shelf_style',
        };
    }

    let name = '';
    if (
        productDataStore.current.cabinet &&
        productDataStore.current.cabinet.attributes &&
        productDataStore.current.cabinet.attributes.original_name
    ) {
        name = productDataStore.current.cabinet.attributes.original_name;
    }

    const options = {
        cabinetName: name,
        type: COMPONENT_TYPES.SHELVES,
        canvasHeight: 500,
        width: width,
        height: height,
        simpleShelvesAmount: values.simple_shelves_amount,
        simpleShelvesType: values.simple_shelf_type,
        isAdvanced:
            values.lower_shelves ||
            values.upper_shelves ||
            values.middle_shelves ||
            values.cabinet_hori_shelves ||
            values.cabinet_vert_shelves ||
            !values.cabinet_simple_shelves
                ? true
                : false,
        advancedShelves: values.shelves,
        drawerMargins: {
            // Not adding bottom margin as that is handled by face height
            top: values.cabinet_drawer_top ? values.cabinet_drawer_top : false,
            left: values.cabinet_drawer_left
                ? values.cabinet_drawer_left
                : false,
            right: values.cabinet_drawer_right
                ? values.cabinet_drawer_right
                : false,
        },
        edgeColor: carcaseEdgeImage,
        color: carcaseImage,
        extColor: extImage,
        extEdgeColor: extEdgeImage,
        horizontal: horizontal,
        horizontalExt: values.hor_grain_ext,
        lowerPartitionHeight: values.cabinet_lower_partition_height,
        partitionHeight,
        drawerFaceHeight: faceHeight,
        upperPartitionHeight: values.cabinet_upper_partition_height,
        lowerShelves: values.lower_shelves,
        middleShelves: values.middle_shelves,
        upperShelves,
        horizontalShelves: values.cabinet_hori_shelves,
        verticalShelves: values.cabinet_vert_shelves,
        shelvesMapper,
        hasBase: previewOptions.bottom,
        hasTop: previewOptions.top,
        hasRight: previewOptions.right,
        hasLeft: previewOptions.left,
        hasBack: previewOptions.back,
        isInner,
        innerText,
        partitionWidth: -1,
    };

    if (options.lowerPartitionHeight && options.lowerPartitionHeight > 1) {
        options.lowerPartitionHeight = options.lowerPartitionHeight / height;
    }

    if (options.upperPartitionHeight && options.upperPartitionHeight > 1) {
        options.upperPartitionHeight = options.upperPartitionHeight / height;
    }

    if (values.cabinet_partition_width) {
        options.partitionWidth =
            values.cabinet_partition_width < 1
                ? values.cabinet_partition_width * values.cabinet_width
                : values.cabinet_partition_width;
    }

    if (values.oven_opening_height) {
        options.oven_opening_height = values.oven_opening_height;
    }

    if (values.microwave_opening_height) {
        options.microwave_opening_height = values.microwave_opening_height;
    }

    if (values.rem_door_shelf_height) {
        options.rem_door_shelf_height = isNaN(values.rem_door_shelf_height)
            ? excel.calculate(values.rem_door_shelf_height, {
                  ...values,
                  ...materials,
              })
            : values.rem_door_shelf_height;
    }

    if (values.cabinet_toekick) {
        options.cabinet_toekick = values.cabinet_toekick;
    }

    if (
        includeVoid &&
        values.cabinet_cover_void &&
        values.cabinet_void_width &&
        values.cabinet_void_width > 0
    ) {
        options.doorVoid = values.cabinet_void_width;

        if (
            productDataStore.current.hasOwnProperty('cabinet') &&
            productDataStore.current.cabinet.hasOwnProperty('attributes') &&
            productDataStore.current.cabinet.attributes.hasOwnProperty(
                'original_name'
            )
        ) {
            if (
                productDataStore.current.cabinet.attributes.original_name
                    .toLowerCase()
                    .includes('right return')
            ) {
                options.doorVoidSide = 'right';
            } else {
                options.doorVoidSide = 'left';
            }
        }
    }

    return options;
};

type ShelvesPreviewOptionType = {
    values: Object,
    productDataStore: Object,
    materials: Object,
    previewOptions: Object,
    includeVoid?: boolean,
};

export const useShelvesPreview = (
    previewOptions: ShelvesPreviewOption
): {position: CSSProperties, canvasContainer: string} => {
    const isSmallDevice = isDeviceSmall();
    const {values, getMaterialOptions, productDataStore, currentTab} =
        usePreviewContext();
    const position: CSSProperties = useStickyCanvas();
    const canvasContainer = useRef('canvas-container-shelves').current;

    const updateShelvesPreview = ({
        values,
        componentPainter,
    }: {
        values: Object,
        componentPainter: ComponentPainter,
    }) => {
        const materials: MaterialOptions = getMaterialOptions();
        const options = getShelvesOptions({
            values,
            productDataStore,
            previewOptions,
            materials,
        });

        componentPainter.init(options);
    };

    const debouncedEffect = useRef(
        throttle(updateShelvesPreview, 500, {
            leading: false,
            trailing: true,
        })
    ).current;

    useEffect(() => {
        const componentPainter = new ComponentPainter(
            canvasContainer,
            isSmallDevice ? 300 : 500
        );

        debouncedEffect({values, componentPainter});
    }, [values, currentTab]);

    return {
        position,
        canvasContainer,
    };
};

export const useShelvesInit = (fieldsetName: string): null => {
    const {setPositionType} = useProductContext();

    useEffect(() => {
        setPositionType(fieldsetName, POSITION_TYPES.PERCENTAGE);
    }, []);

    return null;
};
