import {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {
    getProductBreadcrumb,
    getProductDetails,
    getProductStyles,
} from 'service';
import {useQueryParams} from 'shared/helpers';
import {mapBreadcrumb} from 'hooks/Product/helpers/mapBreadcrumb';

export interface BreadCrumbApi {
    id: number;
    name: string;
    category?: number;
}

export interface BreadCrumb {
    id?: number;
    key: string;
    link?: string;
}

export const useBreadCrumb = () => {
    const navigate = useNavigate();
    const {jobId, roomId} = useParams();
    const {product, category, subCategory, favourites} = useQueryParams() as {
        product: string;
        category: string;
        subCategory: string;
        favourites: string;
    };
    const [breadcrumbs, setBreadcrumbs] = useState<BreadCrumb[]>([]);
    const location = useLocation();

    const addBreadCrumb = (item: BreadCrumb[]) => {
        if (Array.isArray(item)) setBreadcrumbs(item);
        else setBreadcrumbs([item]);
    };

    const removeBreadCrumb = (removeKey: string) => {
        const filteredBreadCrumb = breadcrumbs.filter(
            (item) => item.key !== removeKey
        );
        setBreadcrumbs(filteredBreadCrumb);

        if (filteredBreadCrumb.length) {
            void navigate(
                filteredBreadCrumb[filteredBreadCrumb.length - 1].link
            );
        } else {
            if (roomId) {
                void navigate(location.pathname);
            } else {
                void navigate(`/v2/job/${jobId}/hardware`);
            }
        }
    };

    const popBreadCrumb = (index?: number) => {
        if (typeof index !== 'undefined') breadcrumbs.length = index + 1;
        else breadcrumbs.pop();

        setBreadcrumbs(breadcrumbs);
    };

    const resetBreadCrumb = (items: BreadCrumb[] = []) => {
        setBreadcrumbs(items);
    };

    const fetchBreadcrumbs = async (
        product?: string,
        category?: string,
        subCategory?: string,
        favourites?: string
    ) => {
        if (favourites) {
            setBreadcrumbs([
                {
                    key: 'Favourites',
                },
            ]);
        } else {
            const productStyles = await getProductStyles();

            let productDetails;

            if (product) {
                productDetails = (await getProductDetails(product)) as {
                    style: string;
                    sub_style_id: string;
                    name: string;
                };

                if (productDetails.style) {
                    category = productDetails.style;
                }

                if (productDetails.sub_style_id) {
                    subCategory = productDetails.sub_style_id;
                }
            }

            const breadCrumbs = await getProductBreadcrumb(
                category,
                subCategory,
                productStyles
            );

            if (category) {
                let mappedBreadCrumbs: BreadCrumb[] = [];

                if (roomId) {
                    if (breadCrumbs.length > 0) {
                        mappedBreadCrumbs = mapBreadcrumb(
                            breadCrumbs,
                            Number(jobId),
                            Number(roomId),
                            location
                        );
                    }
                } else {
                    if (breadCrumbs.length > 1) {
                        mappedBreadCrumbs = mapBreadcrumb(
                            breadCrumbs,
                            Number(jobId),
                            Number(roomId),
                            location
                        );
                    }
                }

                if (productDetails) {
                    mappedBreadCrumbs.push({
                        key: productDetails.name,
                    });
                }

                setBreadcrumbs(mappedBreadCrumbs);
            }
        }
    };

    useEffect(() => {
        setBreadcrumbs([]);

        void fetchBreadcrumbs(product, category, subCategory, favourites);
    }, [product, category, subCategory, favourites]);

    return {
        breadcrumbs,
        addBreadCrumb,
        removeBreadCrumb,
        popBreadCrumb,
        resetBreadCrumb,
    };
};
