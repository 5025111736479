import {useCallback, useEffect, ReactElement} from 'react';
import {
    SidebarStatus,
    SidebarBehaviour,
    SidebarName,
    SidebarBehaviourName,
    setAlternateCartContent as setAlternateCartContentAction,
    setRightSidebarStatus,
    setRightSidebarBehaviour as setRightSidebarBehaviourAction,
    setLeftSidebarStatus,
    setLeftSidebarBehaviour as setLeftSidebarBehaviourAction,
    selectRightSidebar,
    selectRightSidebarBehaviour,
    selectLeftSidebar,
    selectLeftSidebarBehaviour,
    selectAlternateCartContent,
} from 'store/customer/UISlice';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {shallowEqual} from 'react-redux';

export const SIDEBAR = {
    LEFT: 'left',
    RIGHT: 'right',
};

export const useSidebarBehaviour = () => {
    const dispatch = useAppDispatch();

    const rightSidebar = useAppSelector(selectRightSidebar);
    const rightSidebarBehaviour = useAppSelector(selectRightSidebarBehaviour);

    const leftSidebar = useAppSelector(selectLeftSidebar);
    const leftSidebarBehaviour = useAppSelector(selectLeftSidebarBehaviour);

    const alternateCartContent = useAppSelector(
        selectAlternateCartContent,
        shallowEqual
    );

    const setRightSidebar = useCallback((status: SidebarStatus) => {
        localStorage.setItem(SidebarName.RIGHT, status);
        dispatch(setRightSidebarStatus(status));
    }, []);

    const setRightSidebarBehaviour = useCallback(
        (behaviour: SidebarBehaviour) => {
            localStorage.setItem(SidebarBehaviourName.RIGHT, behaviour);
            dispatch(setRightSidebarBehaviourAction(behaviour));
        },
        []
    );

    const setLeftSidebar = useCallback((status: SidebarStatus) => {
        localStorage.setItem(SidebarName.LEFT, status);
        dispatch(setLeftSidebarStatus(status));
    }, []);

    const setLeftSidebarBehaviour = useCallback(
        (behaviour: SidebarBehaviour) => {
            localStorage.setItem(SidebarBehaviourName.LEFT, behaviour);
            dispatch(setLeftSidebarBehaviourAction(behaviour));
        },
        []
    );

    const setAlternateCartContent = useCallback((content: ReactElement) => {
        if (typeof content == 'undefined') {
            dispatch(setAlternateCartContentAction(undefined));

            return;
        }

        dispatch(setAlternateCartContentAction(content));
    }, []);

    useEffect(() => {
        // set to hidden if path is not job
    }, []);

    return {
        alternateCartContent,
        leftSidebar,
        leftSidebarBehaviour,
        rightSidebar,
        rightSidebarBehaviour,
        setAlternateCartContent,
        setRightSidebar,
        setRightSidebarBehaviour,
        setLeftSidebar,
        setLeftSidebarBehaviour,
    };
};
