import {GenericObject} from 'components/customer/Product/entity';
import {Structure} from 'components/customer/Product/entity/Structure';
import {productConfigIterator} from 'components/customer/Product/helpers/productConfigIterator';

export const getSectionFieldsMap = (structure: Structure[]) => {
    const fields: GenericObject = {};
    const configIterator = productConfigIterator(structure);

    let config;
    while ((config = configIterator.next())) {
        if (typeof config.value === 'undefined') break;

        const {field, structureIndex} = config.value;

        fields[field.name] = structureIndex;

        if (field.name == 'drawer_runner_specs') {
            if ('drawerHeightFieldName' in field.options) {
                fields[field.options.drawerHeightFieldName] = structureIndex;
            }
        }
    }

    return fields;
};
