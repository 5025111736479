import {BreadCrumb, BreadCrumbApi} from 'hooks/Product/useBreadCrumb';
import {Location} from 'react-router-dom';

export const mapBreadcrumb = (
    breadCrumb: BreadCrumbApi[],
    jobId: number,
    roomId?: number,
    location?: Location
) => {
    const searchKeys: BreadCrumb[] = [];

    Array.isArray(breadCrumb) &&
        breadCrumb.length &&
        breadCrumb.forEach((item) => {
            let link = `/v2/job/${jobId}/hardware?category=${item.id}`;

            if (roomId) {
                link = `${location.pathname}?category=${item.id}`;

                if (item.hasOwnProperty('category')) {
                    link = `${location.pathname}?category=${item.category}&subCategory=${item.id}`;
                }
            }

            searchKeys.push({
                link,
                key: item.name,
                id: item.id,
            });
        });

    return searchKeys;
};
