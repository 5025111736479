// @flow
import {useFormikContext} from 'formik';
import {useStickyCanvas} from 'hooks';
import {useEffect, useRef, useMemo, useCallback} from 'react';
import {ComponentPainter, COMPONENT_TYPES} from 'shared';
import excel from 'shared/Excel';
import {getFieldValue, sumArrayVal, toFixed} from 'shared/helpers';
import {cloneDeep} from 'lodash';
import {CSSProperties} from 'styled-components';

import {getQFPFaceHeights} from 'components/customer/QFPRedux/helpers/getQFPFaceHeights';
import {usePreviewContext} from 'components/customer/Product/Preview/PreviewContext';

export const useDrawers = ({
    isAdvanced,
}: {
    isAdvanced: boolean,
}): {position: CSSProperties, canvasContainerId: string} => {
    const position: CSSProperties = useStickyCanvas();
    const {values, getMaterialOptions, currentTab} = usePreviewContext();
    const canvasContainerId = useRef<string>('canvas-container-drawer').current;

    useEffect(() => {
        try {
            const materials = getMaterialOptions();
            const componentPainter = new ComponentPainter(
                canvasContainerId,
                375,
                undefined,
                true
            );

            let edgeMaterial;
            let exteriorMaterial;

            if (materials.exterior_edge_color) {
                if (
                    materials.exterior_edge_color.indexOf(
                        'uploads/gocabinet_materials'
                    ) > -1
                )
                    edgeMaterial = `${materials.exterior_edge_color}`;
                else
                    edgeMaterial = `/uploads/gocabinet_materials/${materials.exterior_edge_color}`;
            } else {
                edgeMaterial = '/templates/wood2.jpg';
            }

            if (materials.exterior_color) {
                if (
                    materials.exterior_color.indexOf(
                        'uploads/gocabinet_materials'
                    ) > -1
                )
                    exteriorMaterial = `${materials.exterior_color}`;
                else
                    exteriorMaterial = `/uploads/gocabinet_materials/${materials.exterior_color}`;
            } else {
                exteriorMaterial = '/templates/young_beech.jpg';
            }

            let width: number = parseInt(
                values.cabinet_width
                    ? values.cabinet_width
                    : values.cabinet_panel_width
            );
            if (
                isNaN(width) &&
                values.hasOwnProperty('cabinet_left_width') &&
                values.hasOwnProperty('cabinet_right_width')
            ) {
                const leftWidth: number = values.cabinet_left_width;
                const rightWidth: number = values.cabinet_right_width;
                width = Math.floor(
                    Math.sqrt(Math.pow(leftWidth, 2) + Math.pow(rightWidth, 2))
                );
            }

            let height: number = parseInt(
                values.cabinet_height
                    ? values.cabinet_height
                    : values.cabinet_panel_length
            );

            if (values.cabinet_total_drawer_height) {
                height = values.cabinet_total_drawer_height;
            }

            const options = {
                type: COMPONENT_TYPES.DRAWER,
                width,
                height,
                drawers: values.drawers
                    ? values.drawers
                    : values.drawer_face_height.map((face_height) => ({
                          drawer_face_height: face_height,
                      })),
                panelEdgeTop:
                    typeof values.drawer_panel_edge_top !== 'boolean'
                        ? parseInt(values.drawer_panel_edge_top) > 0
                        : values.drawer_panel_edge_top,
                panelEdgeLeft:
                    typeof values.drawer_panel_edge_left !== 'boolean'
                        ? parseInt(values.drawer_panel_edge_left) > 0
                        : values.drawer_panel_edge_left,
                panelEdgeBottom:
                    typeof values.drawer_panel_edge_bottom !== 'boolean'
                        ? parseInt(values.drawer_panel_edge_bottom) > 0
                        : values.drawer_panel_edge_bottom,
                panelEdgeRight:
                    typeof values.drawer_panel_edge_right !== 'boolean'
                        ? parseInt(values.drawer_panel_edge_right) > 0
                        : values.drawer_panel_edge_right,
                faceType: values.drawer_face_type, // 2 is collective face
                joiningBorderHeight: values.drawer_hori_height,
                joiningBorder: values.drawer_panel_edge_join,
                edgeMaterial: edgeMaterial,
                exteriorMaterial: exteriorMaterial,
                gap:
                    values.drawers && values.drawers.length > 1
                        ? values.cabinet_drawer_gap
                        : 0,
            };

            if (isAdvanced) {
                options.borderTop = parseInt(values.drawer_border_width_top);
                options.borderLeft = parseInt(values.drawer_border_width_left);
                options.borderBottom = parseInt(
                    values.drawer_border_width_bottom
                );
                options.borderRight = parseInt(
                    values.drawer_border_width_right
                );
            }

            options.horizontal = values.hor_grain_ext;
            componentPainter.init(options);
        } catch (e) {
            console.log(e);
        }
    }, [
        values.drawer_amount ? values.drawer_amount : 0,
        values.cabinet_drawer_gap ? values.cabinet_drawer_gap : 0,
        values.cabinet_width ? values.cabinet_width : 0,
        values.cabinet_height ? values.cabient_height : 0,
        values.cabinet_panel_width ? values.cabinet_panel_width : 0,
        values.cabinet_panel_length ? values.cabinet_panel_length : 0,
        values.hasOwnProperty('cabinet_left_width')
            ? values.cabinet_left_width
            : 0,
        values.hasOwnProperty('cabinet_right_width')
            ? values.cabinet_right_width
            : 0,
        values.drawers,
        values.drawer_face_height,
        values.drawer_panel_edge_top,
        values.drawer_panel_edge_left,
        values.drawer_panel_edge_bottom,
        values.drawer_panel_edge_right,
        values.drawer_border_width_top,
        values.drawer_border_width_left,
        values.drawer_border_width_bottom,
        values.drawer_border_width_right,
        values.cabinet_ext_colour,
        values.cabinet_ext_edge_colour,
        values.drawer_face_type,
        values.drawer_hori_height,
        values.drawer_panel_edge_join,
        values.cabinet_drawer_gap,
        values.hor_grain_ext,
        currentTab,
    ]);

    return {position, canvasContainerId};
};

export const useComponentDrawer = (field, fieldset, selectHandler) => {
    const {values, setFieldValue} = useFormikContext();

    const fieldName = useRef();
    const gapField = useRef();
    const faceHeightsRef = useRef('');
    const manuallyUpdatedFace = useRef([]);

    if (typeof fieldName.current === 'undefined') {
        fieldName.current =
            fieldset.options && fieldset.options.faceHeightField
                ? fieldset.options.faceHeightField
                : 'drawer_face_height';
    }

    if (typeof gapField.current === 'undefined') {
        gapField.current =
            fieldset.options && fieldset.options.drawerGap
                ? fieldset.options.drawerGap
                : 'cabinet_drawer_gap';
    }

    const gapRef = useRef(values[gapField.current]);
    const getTotalHeight = useCallback(() => {
        try {
            const calculatedHeight = excel.calculate(
                fieldset.options.totalHeight,
                values
            );
            return calculatedHeight;
        } catch (e) {
            return 0;
        }
    }, [values]);

    const getQuantity = useCallback(() => {
        const quantity = fieldset.hasOwnProperty('quantity')
            ? fieldset.quantity
            : 1;
        return isNaN(quantity)
            ? values.hasOwnProperty(quantity)
                ? values[quantity]
                : 1
            : quantity;
    }, [values]);

    const [totalHeight, quantity] = useMemo(() => {
        const totalHeightValue = getTotalHeight();
        const quantityValue = getQuantity();

        return [totalHeightValue, quantityValue];
    }, [values]);

    const fieldsets = useMemo(() => {
        if (
            values[fieldName.current] &&
            Array.isArray(values[fieldName.current])
        ) {
            return values[fieldName.current].map((value, index) => {
                const faceHeight = cloneDeep(fieldset);

                faceHeight.fields.map((field) => {
                    field.displayName = getFieldValue(field.displayName, {
                        fieldset: {index},
                    });
                    field.value = `( ${faceHeight.options.totalHeight} ) / drawer_amount`;

                    field.options = {
                        enabled: `${index} != (drawer_amount - 1)`,
                    };

                    return field;
                });

                return faceHeight;
            });
        }

        return [];
    }, [values[fieldName.current]]);

    const updateManuallyUpdatedFace = (index) => {
        if (!manuallyUpdatedFace.current.includes(index)) {
            manuallyUpdatedFace.current.push(index);
        }
    };

    useEffect(() => {
        if (fieldName.current && totalHeight > 0) {
            let faceHeightValues = values[fieldName.current];

            if (quantity > 0) {
                const gap = values[gapField.current];
                const {
                    faceHeightValues: updatedFaceHeights,
                    updateGap,
                    clearManualEntries,
                } = getQFPFaceHeights(
                    quantity,
                    totalHeight,
                    gap,
                    gapRef.current,
                    manuallyUpdatedFace.current,
                    faceHeightValues
                );

                faceHeightValues = updatedFaceHeights;

                if (updateGap) gapRef.current = gap;
                if (clearManualEntries) manuallyUpdatedFace.current = [];
            } else {
                faceHeightValues = [];
            }

            if (selectHandler) {
                selectHandler(fieldName.current, faceHeightValues);
            } else {
                setFieldValue(fieldName.current, faceHeightValues);
            }
        }
    }, [quantity, totalHeight]);

    useEffect(() => {
        if (
            fieldName.current &&
            JSON.stringify(faceHeightsRef.current) !=
                JSON.stringify(values[fieldName.current])
        ) {
            const oldFaceHeights = JSON.parse(
                JSON.stringify(
                    faceHeightsRef.current ? faceHeightsRef.current : []
                )
            );

            faceHeightsRef.current = values[fieldName.current];

            let newFaceHeights = JSON.parse(
                JSON.stringify(values[fieldName.current])
            );
            let updateValueFrom;
            let heightOffset = 0;

            const intersect = newFaceHeights
                .map(Number)
                .filter((faceHeight, index) => {
                    const isSameAsOldValue =
                        oldFaceHeights.map(Number)[index] == faceHeight;

                    if (!isSameAsOldValue) {
                        updateValueFrom = index + 1;
                        heightOffset = sumArrayVal(newFaceHeights, index);
                    }

                    return isSameAsOldValue;
                });

            if (
                intersect.length > 0 &&
                intersect.length < newFaceHeights.length
            ) {
                if (manuallyUpdatedFace.current.length) {
                    manuallyUpdatedFace.current =
                        manuallyUpdatedFace.current.filter(
                            (index) => index < updateValueFrom
                        );
                }

                newFaceHeights = newFaceHeights.map((faceHeight, index) => {
                    if (index >= updateValueFrom) {
                        faceHeight = toFixed(
                            (totalHeight - heightOffset) /
                                newFaceHeights.filter(
                                    (height, i) => i >= updateValueFrom
                                ).length,
                            2
                        );
                    }

                    return faceHeight;
                });

                faceHeightsRef.current = newFaceHeights;

                if (selectHandler)
                    selectHandler(fieldName.current, newFaceHeights);
                else setFieldValue(fieldName.current, newFaceHeights);
            }
        }
    }, [
        values.hasOwnProperty(fieldName.current)
            ? values[fieldName.current]
            : 0,
    ]);

    return {
        fieldName: fieldName.current,
        fieldsets,
        updateManuallyUpdatedFace,
    };
};
